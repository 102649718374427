<template>
<div class="route-outer">
  <div class="route center">
    <br>
    <ATitle text="交通指引" icon="8"/>
    <br>
    <div class="block">
      <DTitle text="公交路线"/>
      <br>
      <br>
      <div class="flex top">
        <div class="wrap">
          <div class="item">
            <dot></dot>
            <div class="dot"></div>
            <h2>萧山国际机场→杭州国际博览中心(2号门)：</h2>
            <p>• 可乘出租车直接抵达，车程约20公里。</p>
            <p>• 可乘坐机场大巴(滨江线)至江陵路地铁站，步行90米至公交站(地铁江陵路站)，换乘419B路/419路到杭州国际博览中心下。</p>
          </div>
          <div class="item">
            <dot></dot>
            <h2>杭州站→杭州国际博览中心(2号门)：</h2>
            <p>• 可乘出租车直接抵达，车程约9公里。</p>
            <p>• 可在公交站(城站火车站)乘坐108路，至钱江路城星路口，换乘317路到达金鸡路盈丰路口下车，步行924米即到达杭州国际博览中心。</p>
            <p>• 可在城站地铁A2口乘坐地铁1号线(往湘湖方向)，至江陵路地铁站A口下车，步行315米至地铁江陵路站公交站，乘坐419路/419B路至杭州国际博览中心。</p>
          </div>
        </div>
        <div class="wrap">
          <div class="item">
            <dot></dot>
            <div class="dot"></div>
            <h2>杭州东站→杭州国际博览中心(2号门)：</h2>
            <p>• 可乘出租车直接抵达，车程约10公里。</p>
            <p>• 可在公交站(火车东站东)乘坐123路/K123路，至地铁振宁路站，换乘419路到杭州国际博览中心下。</p>
          </div>
          <div class="item">
            <dot></dot>
            <h2>杭州南站→杭州国际博览中心(2号门)：</h2>
            <p>• 可乘出租车直接抵达，车程约11公里。</p>
            <p>• 可在火车南站地铁站乘坐地铁5号线(往金星方向)，至人民广场站换乘地铁2号线（往良渚方向）盈丰路站C口下车，步行21米至地铁盈丰站公交站，乘坐419B路到达杭州国际博览中心下。</p>
            <p>• 可步行493米在公交站(火车南站西站)乘坐328路，至陆路口岸公交站，换乘419路到达杭州国际博览中心下。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="block fl">
      <br>
      <DTitle text="自驾路线"/>
      <br>
      <br>
      <div class="wrap">
        <div class="item">
          <dot></dot>
          <p>杭州国际博览中心（奔竞大道353号）</p>
          <p>由奔竞大道进入杭州国际博览中心三号门地下停车场A区。</p>
        </div>
      </div>
      <br>
      <br>
      <DTitle text="地铁路线"/>
      <br>
      <br>
      <div class="wrap">
        <div class="item">
          <dot></dot>
          <p>地铁6号线博览中心站（A口出）</p>
        </div>
      </div>
    </div>
    <div class="block fr" style="width: 530px">
      <a href="https://www.amap.com/search?id=B0FFG18EXS&city=330109&geoobj=119.964749%7C30.130654%7C120.452266%7C30.449933&query_type=IDQ&query=杭州国际博览中心&zoom=12" target="_black" class="map-wrap" style="margin-top: 20px">
        <div id="container"></div>
      </a>
    </div>
  </div>
</div>
  
</template>
<script>
export default {
  data() {
    return {

    }
  },
  mounted() {
    // eslint-disable-next-line
    this.map = new AMap.Map("container", {
      resizeEnable: true,
      center: [120.238786,30.229507],
      zoom: 15,
    })
    // let marker = new AMap.Marker({
    //   title: '杭州国际博览中心',
    //   map: this.map,
    //   icon: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAAhCAMAAAAMPGBYAAABS2lUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4KPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxMzggNzkuMTU5ODI0LCAyMDE2LzA5LzE0LTAxOjA5OjAxICAgICAgICAiPgogPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIi8+CiA8L3JkZjpSREY+CjwveDp4bXBtZXRhPgo8P3hwYWNrZXQgZW5kPSJyIj8+IEmuOgAAAARnQU1BAACxjwv8YQUAAAABc1JHQgCuzhzpAAABa1BMVEUAAADDOC3CNirMPDMAKhXHPDHFOzAALQ8ALBMAAADNQDPAOC3zWUn0WUrxV0n/ZjPxWEj1WE6/Pz+/NSvoTULtVUXPQTXrUkXmUEHSQja7NCnhTD/RQTTWSDvUQzcAKxL/AAC6MikAKg+1LyafMCW4MybIPTDJPDDzWUnxVkf0WEnvT0/0WErxV0juVUbpUUH/VVXwVUbvVUbpUEP/VVXcSz3aRjvjTkDfSj3aRzrcSj3jT0DfSj64Mii4Mih0NyawLyR1Nyj/Tkr7QUD8Q0H9RkP+SUX6Pz75PDz4OTr3Nzj+SUb/Tkn2Njf0Mjb1NDbyLTLzLzTxKzDwKi/vKC7uJS3tJCztIirhLy7dLCvXNzHpMjH3U0n9Ukv5VUr/UEv/UkzgOTT3UUjyNDX8SkbzTkT3SEP1Ukf6RUHeJyj5Pz7rQDvoPTj0PzzfRDrKLijlIijSJSS/LCX/UEzrHijrHSfTICHjHibsISmDQxFYAAAAQnRSTlMApypQGC79MygC3v7ldYMF9hoIsBeAgfa++ET9JzjAKQHdMNyoKLy9+b3BEMC+vHQDEsZ1Bs+R6fOT0Ojyf35p9GoZQgpXAAABHklEQVQoz13QVXPCUBCG4UUT3N2t7u601N0T3N2h+vNLeibkHJ7Ld2Yv9gPgGDQL6hn1nMYAPJ1Wn83kP/KZrF6rQ2l+Np2LIbn04iqXVpaKMUFxbWPULLU7XM0CYLaVb3FlmxmWKxekyjrYq+ekqh2262ek+j64Tie5YKd5SWoewGbnitTZg63WNam1C57uDanrAfC273Ft7+g3t6P0KCg53NwI1sKDoGD938qoTD3zUkojGlCSfOIlJfzQU/EXJD49Hl8mf0XksnEDUeKNkxAJCcSqd45KjDUwNRiGaZjwBM4ey7I9J9H8gf5vP+AnGhwNPgeHZILI8GsYmWgQ+gmRQSGlw99hWqrAGk1R0eMTiqKx5qMQH3kbpIL87R8BqGu5YEEr6QAAAABJRU5ErkJggg=='
    // })
  }
}
</script>
<style lang="less" scoped>
.route-outer {
  background: url('../assets/views_bg.png') no-repeat scroll bottom / 100%;
}
.route {
  min-height: 1100px;
  p {
    font-size: 16px;
    line-height: 25px;
    color: #666;
    text-align: center;
  }
  .block {
    .wrap {
      margin: 10px;
      flex: 1;
      border-left: 1px solid #0769CC;
      .item {
        position: relative;
        text-align: left;
        padding-left: 25px;
        padding-bottom: 30px;
        h2 {
          text-align: left;
          color: #333;
          font-weight: 600;
          margin-bottom: 20px;
        }
        p {
          text-align: justify;
        }
        dot {
          position: absolute;
          left: -8px;
          top: 5px;
          width: 15px;
          height: 15px;
          border-radius: 15px;
          background: #0769CC;
        }
        .last {
          top: 16px;
        }
      }
      .item:last-child {
        padding-bottom: 0;
      }
    }
  }
  .fr {
    width: 415px;
  }
  .top {
    align-items: flex-start;
  }
}
.map-wrap {
  cursor: pointer;
  display: block;
  height: 300px;
  width: 530px;
  #container {
    height: 100%;
    width: 100%;
    box-shadow: 0 0 10px #CCC;
    border-radius: 4px;
  }
}
</style>
